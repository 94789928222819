// const moment = require('moment');
import campaignTrackingModule from '@/services/campaignTracking';
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import CampaignMixin from '@/mixins/campaignMixin';
// import companyService from '@/services/company';
import moment from 'moment';

export default {
  name: 'CampaignOverview',
  components: {
    DynamicTimelineGraphComponent
  },
  mixins: [CampaignMixin],
  data() {
    return {
      fetchingOverview: true,
      fetchingOverviewGraph: false,
      selectedTab: 'channel',

      // Timing properties
      timingFilter: 'last-7',
      customTimeRange: null,
      displayTimeRange: null,
      campaignOverviewTimingOptions: [
        {
          label: 'Weekly Durations',
          options: [
            {
              title: 'Last 7 Days',
              value: 'last-7'
            },
            {
              title: 'Current Week',
              value: 'this-week'
            },
            {
              value: 'last-week',
              title: 'Last Week'
            }
          ]
        },
        {
          label: 'Monthly Durations',
          options: [
            {
              title: 'Last 30 Days',
              value: 'last-30'
            },
            {
              value: 'this-month',
              title: 'Current Month'
            },
            {
              value: 'last-month',
              title: 'Last Month'
            }
          ]
        },
        {
          label: 'Custom',
          options: [
            {
              value: 'custom',
              title: 'Custom Range'
            }
          ]
        }
      ],

      // Channel Tab
      channelData: null,
      channelDataDaily: null,
      channelDataDailyMetric: 'sent',

      // Campaign Tab
      fetchingCampaignStats: true,
      campaignTableData: null,
      campaignTableFilters: {
        channel: [],
        type: [],
        status: []
      },
      campaignTableFilterOptions: {
        channel: [
          { label: 'Email', value: 'email' },
          { label: 'SMS', value: 'sms' },
          { label: 'WhatsApp', value: 'whatsApp' },
          { label: 'Mobile Push', value: 'mobilePush' },
          { label: 'Web Push', value: 'webPush' },
          { label: 'In-App', value: 'inApp' }
        ],
        status: [
          { label: 'Draft', value: 'draft' },
          { label: 'Scheduled', value: 'scheduled' },
          { label: 'Running', value: 'running' },
          { label: 'Ended', value: 'ended' }
        ],
        type: [
          { label: 'One Time', value: 'oneTime' },
          { label: 'Journey', value: 'journey' }
        ]
      }
    };
  },
  watch: {
    timingFilter: {
      handler: function () {
        if (this.timingFilter != 'custom') {
          this.fetchAllData(false);
        }
      }
    }
  },

  methods: {
    onTabChange(newTab) {
      this.selectedTab = newTab;
      this.fetchAllData(false);
    },

    fetchAllData(checkForCache) {
      if (this.timingFilter == 'custom') {
        let diff = moment(this.customTimeRange[1]).diff(moment(this.customTimeRange[0]), 'days');
        console.log('Days:', diff);
        if (diff > 90) {
          this.warningToast('Maximum 3 months are allowed for analysis.');
          return;
        }
      }

      if (this.selectedTab == 'channel') {
        this.fetchChannelTableData(checkForCache);
        this.fetchChannelGraphData(checkForCache);
      } else {
        this.fetchCampaignTableData(checkForCache);
      }
    },

    //#region ******************** Campaign Tab ********************

    async fetchCampaignTableData(checkForCache) {
      try {
        this.fetchingCampaignStats = true;

        let params = {
          checkForCache: checkForCache,
          timingFilter: this.timingFilter,
          customTimeRange: this.customTimeRange,
          filters: this.campaignTableFilters
        };

        let result = await campaignTrackingModule.getOverview(params, 'campaign');

        this.campaignTableData = result.data.data;

        this.displayTimeRange = {
          start: result.data.startTime,
          end: result.data.endTime
        };

        this.fetchingCampaignStats = false;
      } catch (e) {
        this.errorToast('Something went wrong. Please contact support.');
        this.reportError(e);
      }
    },

    //#endregion ***************** Campaign Tab ********************

    //#region ******************** Channels Tab ********************

    async fetchChannelTableData(checkForCache) {
      try {
        this.fetchingOverview = true;

        let params = {
          checkForCache: checkForCache,
          timingFilter: this.timingFilter,
          customTimeRange: this.customTimeRange
        };

        let result = await campaignTrackingModule.getOverview(params, 'channel');
        this.channelData = result.data.data;

        this.displayTimeRange = {
          start: result.data.startTime,
          end: result.data.endTime
        };

        this.fetchingOverview = false;
      } catch (e) {
        this.errorToast('Something went wrong. Please contact support.');
        this.reportError(e);
      }
    },

    async fetchChannelGraphData(checkForCache) {
      try {
        this.fetchingOverviewGraph = true;

        let params = {
          checkForCache: checkForCache,
          timingFilter: this.timingFilter,
          customTimeRange: this.customTimeRange
        };

        let result = await campaignTrackingModule.getOverviewDaily(params, this.channelDataDailyMetric);

        this.displayTimeRange = {
          start: result.data.startTime,
          end: result.data.endTime
        };

        this.channelDataDaily = {
          data: result.data.data,
          durationGroup: 'day',
          durationType: 'exact',
          startTime: result.data.startTime,
          endTime: result.data.endTime
        };
        this.fetchingOverviewGraph = false;
      } catch (e) {
        this.errorToast('Something went wrong. Please contact support.');
        this.reportError(e);
      }
    },

    //#endregion ******************** Channels Tab ********************

    async exportReport() {
      try {
        this.fetchingOverview = true;

        let params = {
          timingFilter: this.timingFilter,
          customTimeRange: this.customTimeRange
        };

        let result = await campaignTrackingModule.sendOverviewCSVReport(params);

        // Show Success Dialog
        this.$swal({
          title: 'Export Started',
          text: result.data.message,
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#67C23A',
          confirmButtonText: 'Okay',
          allowEscapeKey: false,
          allowOutsideClick: false
        });

        // Report event.
        this.marketingTrackEvent('Overview Report Downloaded', {
          'Timing Filter': this.timingFilter,
          Org: this.$store.state.token
        });

        this.fetchingOverview = false;
      } catch (e) {
        this.errorToast('Failed to export report. Please contact support.');
        this.reportError(e);
      }
    }
  },

  mounted() {
    // companyService.deleteCompany();
    this.fetchAllData(true);
  }
};
